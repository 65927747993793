import React, { useState } from 'react';
import '../Footer/Footer.css'; 
import Modal from "../Modal/Modal";
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';

const Footer = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    query: ''
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false); // State to manage submit button disable
  const currentYear = new Date().getFullYear();  

  const showModalHandler = (title) => {
    setModalTitle(title);
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const validate = (name, value) => {
    let errors = { ...formErrors };

    switch (name) {
      case 'name':
        errors.name = /^[A-Za-z\s]+$/.test(value) ? '' : 'Name should contain only alphabets.';
        break;
      case 'email':
        errors.email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? '' : 'Invalid email address.';
        break;
      case 'mobile':
        errors.mobile = /^\d{10}$/.test(value) ? '' : 'Mobile number should be 10 digits.';
        break;
      case 'query':
        errors.query = value.length >= 15 ? '' : 'Query should be at least 15 characters long.';
        break;
      default:
        break;
    }

    setFormErrors(errors);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    validate(name, value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (Object.values(formErrors).every(error => error === '')) {
      setIsSubmitting(true); // Disable the button
      
      emailjs.sendForm('service_gwx9h8c', 'template_8fchos7', e.target, '5vvqi6coftIzkClRe')
        .then((result) => {
          Swal.fire("Success!", "Your query has been submitted!", "success");
          setShowModal(false);
        }, (error) => {
          Swal.fire("Error!", "Something went wrong, please try again.", "error");
        })
        .finally(() => {
          setIsSubmitting(false); // Re-enable the button after the process completes
        });
    }
  };

  return (
    <>
      <footer className="footer">
        <ul className="footer-links">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/layout">Layouts</Link></li>
          <li><Link to="/pricelist">Price List</Link></li>
          <li><a href="#" onClick={() => showModalHandler('Get Our Brochure')}>Brochure</a></li>
          <li><Link to="/contactus">Contact</Link></li>
        </ul>
        <hr className="footer-divider" />
      </footer>

      <Modal show={showModal} handleClose={hideModal}>
        <h2 className="modal-heading">{modalTitle}</h2>
        <form className="modal-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            {formErrors.name && <span className="error">{formErrors.name}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {formErrors.email && <span className="error">{formErrors.email}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="mobile">Mobile:</label>
            <input
              type="tel"
              id="mobile"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              required
            />
            {formErrors.mobile && <span className="error">{formErrors.mobile}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="query">Your Query:</label>
            <textarea
              id="query"
              name="query"
              rows="4"
              value={formData.query}
              onChange={handleChange}
              required
            />
            {formErrors.query && <span className="error">{formErrors.query}</span>}
          </div>
          <div className="butt-f">
            <button className="button-3" type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'SUBMIT'}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default Footer;
