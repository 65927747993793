import React from 'react';
import { FaPhone } from 'react-icons/fa';
import '../Location/Location.css';

const Location = () => {
  return (
    <>
    <div className='full-l'>
       
   
    <div className="location-section">
  
     
      <div className="L-r">
        <h2>Ace Sector 12</h2>
        <h5>Sector 12 Greater Noida West<br />
            Uttar Pradesh</h5>
        <a href="tel:+917303734500" className="call-now">
          <FaPhone className="call-icon" />
          <span>Call Now</span>
        </a>
      </div>
    </div>
    </div>
    </>
  );
};

export default Location;
