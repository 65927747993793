import React, { useState } from "react";
import Modal from "../Modal/Modal"; 
import "../Card/Card.css";
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';

const Card = () => {
  const [showModal, setShowModal] = useState(false);
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    mobile: '',
    query: ''
  });
  const [formErrors, setFormErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState('');

  const cards = [
    { id: 1, image: "f1.jpg", ribbon: "3BHK(2200 Sq.Ft.)" },
    { id: 2, image: "f2.jpg", ribbon: "4BHK+SERVENT(2900 Sq.Ft.)" },
    { id: 3, image: "f3.jpg", ribbon: "4BHK(3700 Sq.Ft.)" },
  ];

  const cards2 = [
    { id: 1, image: "aw.jpeg", ribbon: "UP COMING PROJECT" },
  ];

  const handleImageClick = () => {
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const validate = (name, value) => {
    let errors = { ...formErrors };

    switch (name) {
      case 'name':
        errors.name = /^[A-Za-z\s]+$/.test(value) ? '' : 'Name should contain only alphabets.';
        break;
      case 'email':
        errors.email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? '' : 'Invalid email address.';
        break;
      case 'mobile':
        errors.mobile = /^\d{10}$/.test(value) ? '' : 'Mobile number should be 10 digits.';
        break;
      case 'query':
        errors.query = value.length >= 15 ? '' : 'Query should be at least 15 characters long.';
        break;
      default:
        break;
    }

    setFormErrors(errors);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    validate(name, value);  // Perform validation on each input change
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform validation before submission
    Object.keys(formValues).forEach((key) => validate(key, formValues[key]));

    if (Object.keys(formErrors).some((key) => formErrors[key] !== '')) {
      return;
    }

    setSubmitting(true);
    
    emailjs.sendForm(
      'service_gwx9h8c', 
      'template_8fchos7', 
      e.target,
      '5vvqi6coftIzkClRe' 
    ).then((result) => {
        setSubmitting(false);
        Swal.fire({
          title: 'Success!',
          text: 'Your query has been submitted successfully.',
          icon: 'success',
          confirmButtonText: 'OK'
        });
        setShowModal(false);
        setFormValues({ name: '', email: '', mobile: '', query: '' }); 
      }, (error) => {
        setSubmitting(false);
        setSubmissionError('An error occurred. Please try again later.');
        Swal.fire({
          title: 'Error!',
          text: 'An error occurred while submitting your query. Please try again later.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      });
  };

  return (
    <>
      <div className="card-full2">
        <h2>Ace Sector 12 Layout</h2>
        <div className="card-section2">
          <div className="card-grid2">
            {cards2.map((card2) => (
              <div key={card2.id} className="card2">
                <img
                  src={card2.image}
                  alt={`Card2 ${card2.id}`}
                  className="card-image2"
                  onClick={handleImageClick}
                />
                <div className="ribbon2">{card2.ribbon}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="card-full">
        <h2>Our Flat Sizes</h2>
        <div className="card-section">
          <div className="card-grid">
            {cards.map((card) => (
              <div key={card.id} className="card">
                <img
                  src={card.image}
                  alt={`Card ${card.id}`}
                  className="card-image"
                  onClick={handleImageClick}
                />
                <div className="ribbon">{card.ribbon}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Modal show={showModal} handleClose={hideModal}>
        <h2 className="modal-heading">Get More Details</h2>
        <form className="modal-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formValues.name}
              onChange={handleChange}
              className={`input ${formErrors.name ? 'invalid' : ''}`}
              required
            />
            {formErrors.name && <span className="error">{formErrors.name}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formValues.email}
              onChange={handleChange}
              className={`input ${formErrors.email ? 'invalid' : ''}`}
              required
            />
            {formErrors.email && <span className="error">{formErrors.email}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="mobile">Mobile:</label>
            <input
              type="tel"
              id="mobile"
              name="mobile"
              value={formValues.mobile}
              onChange={handleChange}
              className={`input ${formErrors.mobile ? 'invalid' : ''}`}
              required
            />
            {formErrors.mobile && <span className="error">{formErrors.mobile}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="query">Your Query:</label>
            <textarea
              id="query"
              name="query"
              value={formValues.query}
              onChange={handleChange}
              className={`input ${formErrors.query ? 'invalid' : ''}`}
              rows="4"
              required
            />
            {formErrors.query && <span className="error">{formErrors.query}</span>}
          </div>
          <div className="butt-f">
            <button className="button-3" type="submit" disabled={submitting || Object.keys(formErrors).some((key) => formErrors[key] !== '')}>
              {submitting ? 'Submitting...' : 'SUBMIT'}
            </button>
          </div>
          {submissionError && <p className="submission-error">{submissionError}</p>}
        </form>
      </Modal>
    </>
  );
};

export default Card;
