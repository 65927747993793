import React from "react";
import "../About/About.css";
import Text from "../Text/Text";
import Plan from "../Plan/Plan";
import Details from "../Details/Details";
import { FaArrowDown } from "react-icons/fa";

const About = () => {
  const leftPoints = [
    "ACE Sector 12 Noida Extension is a luxury residential project in Greater Noida West by the reputable ACE Group.",
    "Offers spacious and well-designed 3,4 and 5 BHK apartments.",
    "Designed to maximize space, natural light, and ventilation.",
    "High-end finishes and contemporary interiors for a modern lifestyle.",
    "Secured gated community with 24/7 security and CCTV surveillance.",
    "Proximity to major roads and highways for easy commuting.",
  ];

  const rightPoints = [
    "Amenities include a well-equipped clubhouse, swimming pool, and modern fitness center.",
    "Sports facilities: tennis courts, badminton courts, and jogging tracks.",
    "Eco-friendly living with landscaped gardens and sustainable practices like rainwater harvesting.",
    "Nearby schools, hospitals, shopping malls, and entertainment hubs.",
    "Children's play area with safe and engaging play zones.",
    "Strategic location with excellent connectivity to key areas of the city.",
  ];

  return (
    <>
      <div className="poster-section2">
        <img src="poster.jpg" alt="Poster" className="poster-image2" />
      </div>
      <div className="about-section">
  <h2 className="section-heading2">ACE Sector 12 Noida Extension</h2>
  <div className="about-content">
    {leftPoints.map((point, index) => (
      <div key={index} className="carda">
        <FaArrowDown className="iconar" />
        <p className="card-content">{point}</p>
      </div>
    ))}
    {rightPoints.map((point, index) => (
      <div key={index} className="carda">
        <FaArrowDown className="iconar" />
        <p className="card-content">{point}</p>
      </div>
    ))}
  </div>
</div>


      <Details />
    
    </>
  );
};

export default About;
