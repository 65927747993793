import React from 'react'
import '../Contactus/Contactus.css'
import Connect from './Connect'
import Location from '../Location/Location'
const Contactus = () => {
  return (
    <>
<div className="post-container1">
        <div className="post-image1">
          <img src="a2.jpg" alt="Post" />
          <div className="post-overlay1">
            <h1 className="post-heading1">We're Just a Call Away!</h1>
            
          </div>
        </div>
      </div>


      <Connect/>

     <Location/>
     
    </>
  )
}

export default Contactus