import React from "react";
import "../Text/Text.css";

const Text = () => {
  return (
    <div className="luxury-homes-section">
      <div className="container2">
        <h2 className="section-heading">ACE Sector 12 Noida Extension</h2>
        
        <p className="section-text">
          ACE Sector 12 Noida Extension is a luxury residential project in Greater Noida West, developed by the reputable ACE Group. This project offers spacious and well-designed 3 and 4 BHK apartments, catering to those seeking a high standard of living with modern amenities and excellent connectivity.
        </p>
        
        <h3 className="subheading">Prime Location</h3>
        <p className="section-text">
          Strategically situated in Sector 12, Ace Residential boasts excellent connectivity to major hubs in Delhi NCR. Residents enjoy convenient access to essential amenities like schools, hospitals, shopping centers, and entertainment options, all within easy reach.
        </p>

        <h3 className="subheading">Spacious Living</h3>
        <p className="section-text">
          Choose from a variety of spacious layouts to suit your needs. We offer luxurious 3 BHK apartments measuring 2200 sq ft, expansive 4 BHK options ranging from 2800-2900 sq ft, and palatial 4 BHK + servant room residences spanning 3600-3700 sq ft. These thoughtfully designed homes provide ample space and comfort for families of all sizes.
        </p>
        
        <h3 className="subheading">Modern Amenities</h3>
        <p className="section-text">
          Indulge in a world of luxury amenities at Ace Residential. Our state-of-the-art clubhouse features a range of recreational facilities. Relax and unwind by the luxurious swimming pool, or stay fit at our well-equipped fitness center. Beautifully landscaped gardens and outdoor spaces offer the perfect setting for relaxation and leisure.
        </p>
        
        <h3 className="subheading">State-of-the-Art Amenities</h3>
        <p className="section-text">
          - Clubhouse: A well-equipped clubhouse with spaces for socializing, recreational activities, and events.<br />
          - Swimming Pool: A large, well-maintained swimming pool for relaxation and fitness.<br />
          - Fitness Center: Modern gym with the latest equipment for a healthy lifestyle.<br />
          - Sports Facilities: Including tennis courts, badminton courts, and jogging tracks.<br />
          - Children’s Play Area: Safe and engaging play areas.
        </p>
        
        <h3 className="subheading">Eco-Friendly Living</h3>
        <p className="section-text">
          - Landscaped gardens and ample green spaces to promote a healthy lifestyle.<br />
          - Rainwater harvesting and other sustainable practices.
        </p>
        
        <h3 className="subheading">Security</h3>
        <p className="section-text">
          - 24/7 security with CCTV surveillance and trained security personnel.<br />
          - Secured gated community to ensure residents' safety.
        </p>
        
        <h3 className="subheading">Connectivity</h3>
        <p className="section-text">
          - Proximity to major roads and highways, making commuting easy.<br />
          - Nearby schools, hospitals, shopping malls, and entertainment hubs enhance convenience.
        </p>
        
        <p className="section-text">
          ACE launched a premium residential project in Sector 12, Greater Noida West, known as Noida Extension. Developed by the renowned ACE Group, this project promises a luxurious, modern living experience with many upscale amenities and thoughtfully designed living spaces.
        </p>
      </div>
    </div>
  );
};

export default Text;
