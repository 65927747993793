import React from 'react';
import Slider from 'react-slick';
import { FaSwimmingPool, FaWifi, FaCar, FaDumbbell, FaCamera, FaTree, FaShieldAlt, FaChild, FaLeaf, FaRunning, FaStar, FaCapsules } from 'react-icons/fa';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../Amenities/Amenities.css';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const NextArrow = ({ onClick }) => {
  return (
    <div className="slick-arrow slick-next" onClick={onClick}>
      <FaArrowRight />
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div className="slick-arrow slick-prev" onClick={onClick}>
      <FaArrowLeft />
    </div>
  );
};

const Amenities = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const amenities = [
    { icon: <FaSwimmingPool />, name: 'Swimming Pool' },
    { icon: <FaDumbbell />, name: 'Gym' },
    { icon: <FaCar />, name: 'Parking' },
    { icon: <FaCamera />, name: 'CCTV' },
    { icon: <FaTree />, name: 'Garden' },
    { icon: <FaShieldAlt />, name: 'Security' },
    { icon: <FaChild />, name: 'Play Area' },
    { icon: <FaLeaf />, name: 'Forest' },
    { icon: <FaRunning />, name: 'Jogging Track' },
    { icon: <FaStar />, name: 'Parlor' },
    { icon: <FaCapsules />, name: 'Chemist' },
  ];

  return (
    <div className="amenities-section">
      <h2 className="amenities-title">Ace Sector 12 Up-Coming Amenities</h2>
      <Slider {...settings} className="amenities-slider">
        {amenities.map((amenity, index) => (
          <div key={index} className="amenity-card">
            <div className="amenity-icon">{amenity.icon}</div>
            <p className="amenity-name">{amenity.name}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Amenities;
